$(document).ready(function(){

  $('body').on('submit','.main-horizontal-form', function (e) { 

    var ele = '.main-horizontal-form';
 
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
    } else {
      $.ajax({
        type: "POST",
        url: $(ele).attr('action'),
        data: $(ele).serialize(),
        dataType: 'json', 
        success: function(data){
          if(data.status == "success"){
            $('body').append('<div class="alert alert-success alert-custom" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> '+ data.text_end +' </div>');
            $(ele + ' input:not(button[type="submit"]), textarea').val("");
  
            setTimeout(function() {
              $('.alert.alert-custom').alert('close')
            }, 6000);
          }else{
  
          }
        }
      });
    }
    return false;
  });

});
