$(function(){
  //##### section aside panel form
  $('.search-branch-btn-toggle').on('click', function(){
    var asidePanel = $(this).closest('.wrappper-search-branch');
    if ($(asidePanel).hasClass('wrappper-search-branch_open')) {
      $(asidePanel).removeClass('wrappper-search-branch_open');
    } else {
      $(asidePanel).addClass('wrappper-search-branch_open');
    }
  });//#####  END section aside panel form
});